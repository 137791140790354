<template>
  <LoadingController :loading="loading" :error="error" @retry="retry">
    <slot :state="state" />
  </LoadingController>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import axios from '../../axios.js'
import { deserializeQuery } from '../../utils/searchQuery.js'

import LoadingController from './LoadingController.vue'
export default {
  name: 'SearchStateController',
  components: { LoadingController },
  props: {
    default: px.object,
    s: px.string,
    params: px.object,
  },
  data: () => ({
    error: null,
    loading: false,
    queryState: null,
  }),
  computed: {
    state: {
      get() {
        if (this.loading || this.error) return null
        return {
          ...(this.queryState != null ? this.queryState : this.default),
          ...this.params,
        }
      },
      set(value) {
        if (value != null) {
          value = Object.freeze(value)
        }
        this.queryState = value
      },
    },
  },
  watch: {
    s: {
      immediate: true,
      handler() {
        this.load()
      },
    },
    state(state) {
      this.$emit('update:state', state)
    },
  },
  beforeDestroy() {
    this.cancel()
  },
  methods: {
    cancel() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async load() {
      this.loading = true
      try {
        this.error = null
        this.state = undefined
        this.cancel()
        this.cancelTokenSource = axios.CancelToken.source()
        this.state = await deserializeQuery(this.s, {
          cancelToken: this.cancelTokenSource.token,
        })
        this.$emit('change', this.state)
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        const error = (this.error = axios.extractErrorMessage(e))
        console.error(error, e)
        Sentry.captureException(e)
      } finally {
        this.loading = false
      }
    },
    async retry() {
      return this.load()
    },
  },
}
</script>

<style scoped></style>
