<template>
  <FilterSelection :disabled="disabled" :get-item-string-compare="getRegionNameById" v-model="internalValue">
    <template #selection="{ item }">
      {{ getRegionNameById(item) }}
    </template>
    <FormDropdown
      :options="options"
      :disabled="disabled"
      text-no-selection="Any region"
      text-single-selection="1 region selected"
      :text-multiple-selection="`${internalValue.length} regions selected`"
      v-model="internalValue"
    />
  </FilterSelection>
</template>

<script>
import px from 'vue-types'

import FormDropdown from '@/components/Form/Dropdown.vue'
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'
import { deepFreeze } from '@/helper.js'
import { ESPORTS_REGION_VALUE } from '@/store/modules/search'

import REGIONS from '../../../assets/regions.json'
export default {
  name: 'RegionFilter',
  components: { FilterSelection, FormDropdown },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    value: px.arrayOf(px.string),
  },
  computed: {
    internalValue: {
      get: function () {
        return this.value ? this.value.filter(region => region !== ESPORTS_REGION_VALUE) : []
      },
      set: function (value) {
        return this.$emit('update', value && value.length > 0 ? [...value, ESPORTS_REGION_VALUE] : [])
      },
    },

    options() {
      return deepFreeze(
        Object.entries(REGIONS)
          .filter(([, value]) => value !== ESPORTS_REGION_VALUE)
          .sort(([, a], [, b]) => a.localeCompare(b))
          .map(([value, text]) => ({
            value,
            text,
          }))
      )
    },
  },
  methods: {
    getRegionNameById(regionId) {
      return REGIONS[regionId]
    },
  },
}
</script>

<style lang="scss" scoped></style>
