<template>
  <ImgCell :image="logo_url" :label="label" :variant="variant" :size="size" />
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'
import mixpanel from '@/mixpanel'

export default {
  name: 'EventCell',
  components: { ImgCell },
  props: {
    id: px.string,
    logo_url: px.string,
    link: px.bool.def(false),
    name: px.string,
    size: px.string.def(undefined),
    trackingContext: px.object,
    variant: px.oneOf(['col', 'row', 'row-reverse']).def('col'),
    updated: String,
    to: px.object,
  },
  computed: {
    asLink() {
      return this.link && this.id
    },
    computedTrackingContext() {
      return {
        ...this.trackingContext,
        eventId: this.id,
        eventName: this.name,
      }
    },
    label() {
      return this.name
    },
  },
  methods: {
    trackClick($event) {
      if (this.asLink) {
        mixpanel.track_link({ type: 'team', href: $event.target.href }, this.computedTrackingContext)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.event-cell {
  display: block;
}
</style>
