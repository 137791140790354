<template>
  <ImgCell :image="image || (team && team.image)" :label="name" :variant="variant" />
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'

export default {
  name: 'PlayerCell',
  components: { ImgCell },
  props: {
    name: px.string,
    image: px.string,
    team: px.shape({
      image: px.string,
    }).loose,
    variant: px.string,
  },
}
</script>

<style lang="scss" scoped></style>
