<template>
  <b-form-group :label="label" :label-for="control_id">
    <b-form-datepicker
      :date-format-options="dateFormatOptions"
      :disabled="disabled"
      :id="control_id"
      :min="min"
      :max="max"
      label-no-date-selected="Select date"
      nav-button-variant="warning"
      selected-variant="warning"
      today-variant="warning"
      :value="value"
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>

<script>
import px from 'vue-types'

export default {
  props: {
    disabled: px.bool,
    dateFormatOptions: px.shape({
      year: px.string,
      month: px.string,
      day: px.string,
    }),
    min: px.oneOfType([px.instanceOf(Date), px.string]),
    max: px.oneOfType([px.instanceOf(Date), px.string]),
    label: px.string,
    value: px.string,
  },

  computed: {
    control_id() {
      return this.label.split(' ').join('-').toLowerCase()
    },
  },
}
</script>

<style></style>
