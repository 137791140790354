<template>
  <FilterSelection :disabled="disabled" :get-item-string-compare="getMapNameById" v-model="internalValue">
    <template #selection="{ item }">
      <MapCell :id="item" variant="row" />
    </template>
    <FormDropdown
      v-model="internalValue"
      :disabled="disabled"
      text-no-selection="Any map"
      text-single-selection="1 map selected"
      :text-multiple-selection="`${internalValue.length} maps selected`"
    >
      <DropdownItem
        v-for="map in maps"
        :key="map.id"
        :disabled="disabled"
        :selected="selectedById[map.id]"
        @click.native="toggle(map.id)"
      >
        <MapCell :id="map.id" variant="row" />
      </DropdownItem>
    </FormDropdown>
  </FilterSelection>
</template>

<script>
import px from 'vue-types'

import FormDropdown from '@/components/Form/Dropdown.vue'
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'
import MapCell from '@/components/Table/cells/MapCell.vue'

import DropdownItem from '../../Form/DropdownItem.vue'

export default {
  name: 'MapFilter',
  components: { DropdownItem, FilterSelection, FormDropdown, MapCell },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    value: px.arrayOf(px.string),
  },
  computed: {
    internalValue: {
      get: function () {
        return this.value || []
      },
      set: function (value) {
        return this.$emit('update', value)
      },
    },

    maps() {
      return Object.freeze([...this.$store.getters['static/maps']].sort((a, b) => a.name.localeCompare(b.name)))
    },

    selectedById() {
      return Object.freeze(Object.fromEntries(this.internalValue.map(id => [id, true])))
    },

    options() {
      return this.$store.getters['static/maps'].map(m => ({
        text: m.name,
        value: m.id,
      }))
    },
  },
  methods: {
    getMapNameById(mapId) {
      return this.$store.getters['static/getMapById'](mapId).name
    },
    toggle(mapId) {
      if (this.selectedById[mapId]) {
        this.internalValue = this.internalValue.filter(id => id !== mapId)
      } else {
        this.internalValue = [...this.internalValue, mapId]
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
