<template>
  <ImgCell v-bind="attributes" :image="agent.display_icon_small_url" :label="agent.name" />
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'

export default {
  name: 'AgentCell',
  components: { ImgCell },
  props: {
    id: px.string.isRequired.def(null),
  },
  computed: {
    agent() {
      return this.$store.getters['static/getAgentById'](this.id)
    },
    attributes() {
      // eslint-disable-next-line no-unused-vars
      const { id, ...attrs } = this.$attrs
      return attrs
    },
  },
}
</script>
