<template>
  <AppTable
    ref="table"
    class="tabe"
    :fields="fields"
    :items="computedItems"
    primary-key="id"
    v-bind="$attrs"
    v-on="$listeners"
    thead-tr-class="tabe__row tabe__row--header"
    :tbody-tr-class="rowClass"
  >
    <template #head()="{ label }">
      <span class="tabe__header-value">{{ label }}</span>
    </template>

    <template #cell(interact)="{ item }">
      <div class="tabe__cell-interact">
        <label class="tabe__check" :class="{ bookmarkable: bookmarkable }">
          <input type="checkbox" :disabled="item.status" :checked="isSelected(item)" @change="toggleSelected(item)" />
        </label>

        <BookmarkToggle
          v-if="bookmarkable"
          name="match"
          :value="isBookmarked(item.id)"
          @update="toggleBookmark(item, $event)"
        />
      </div>
    </template>

    <template #cell(selected)="{ item }">
      <label>
        <input type="checkbox" :disabled="item.status" :checked="isSelected(item)" @change="toggleSelected(item)" />
      </label>
    </template>

    <template #cell(bookmark)="data">
      <BookmarkToggle :value="isBookmarked(data.item.id)" @update="toggleBookmark(data.item.id, $event)" />
    </template>

    <template #cell(created)="{ item }">
      <template v-if="item.vod_status">
        <VodStatusIndicator class="tabe__vod tabe__tooltip-wrapper" :status="item.vod_status" :match_id="item.id" />
      </template>
      <template v-else>
        <router-link
          class="tabe__btn-action tabe__btn-action--vod"
          :to="isCollegiate ? `/collegiate/match/${item.id}/spectator` : `/match/${item.id}/spectator`"
          @click.native="trackVodClick($event, item)"
        >
          <span class="tabe__btn-value"> Add VOD </span>
        </router-link>
      </template>

      <time :datetime="item.created | isoString" class="tabe__datetime">
        <span class="tabe__cell-value tabe__cell-date">{{ item.created | localDate }}</span>
        <span class="tabe__cell-value tabe__cell-time">{{ item.created | localTime }}</span>
      </time>
    </template>

    <template #cell(map)="{ item: { map } }">
      <MapCell class="tabe__map" :id="map" variant="col" size="lg" />
    </template>

    <template #cell(winnerTeam)="{ item }">
      <div class="tabe__element">
        <TeamCell
          v-bind="getWinnerTeam(item)"
          class="tabe__element-info"
          :link="!isCollegiate"
          :to="{ query: { s: $route.query.s, map_ids: [item.map] } }"
          :tracking-context="getTrackingContext(item)"
          variant="row-reverse"
          :is-collegiate="isCollegiate"
        />
        <AgentComposition
          :agents="getWinnerTeam(item).composition"
          :highlight="calcHighlightAgents(getWinnerTeam(item))"
          :variant="getWinnerTeam(item).grid"
        />
      </div>
    </template>

    <template #cell(score)="{ item }">
      <div class="tabe__cell-score-wrapper">
        <span class="tabe__cell-score-winner">{{ getWinnerTeam(item).num_points }}</span>
        <span class="tabe__cell-score-separator"> :</span>
        <!-- <span class="tabe__cell-score-separator">vs</span> -->
        <span class="tabe__cell-score-loser">{{ getLoserTeam(item).num_points }}</span>
      </div>
      <div class="tabe__cell-breakdown-wrapper">
        <Breakdown :breakdown="calcBreakdown(getWinnerTeam(item))" />
        <span />
        <Breakdown :breakdown="calcBreakdown(getLoserTeam(item))" />
      </div>
    </template>

    <template #cell(winnerTeamScore)="{ item }">
      {{ getWinnerTeam(item).num_points }}
    </template>

    <template #cell(loserTeamScore)="{ item }">
      {{ getLoserTeam(item).num_points }}
    </template>

    <template #cell(loserTeam)="{ item }">
      <div class="tabe__element">
        <TeamCell
          v-bind="getLoserTeam(item)"
          class="tabe__element-info"
          :link="!isCollegiate"
          :to="{ query: { s: $route.query.s, map_ids: [item.map] } }"
          :tracking-context="getTrackingContext(item)"
          variant="row"
          :is-collegiate="isCollegiate"
        />
        <AgentComposition
          :agents="getLoserTeam(item).composition"
          :highlight="calcHighlightAgents(getLoserTeam(item))"
          :variant="getLoserTeam(item).grid"
        />
      </div>
    </template>

    <template #cell(event)="{ item }">
      <EventCell v-bind="item.event_expand" variant="row" />
    </template>

    <template #cell(rounds)="{ item }">
      <span class="tabe__cell-value">{{ item.rounds }}</span>
    </template>

    <template #cell(vod_status)="{ item }">
      <VodStatusIndicator :status="item.vod_status" :match_id="item.id" />
    </template>

    <template #cell(actions)="{ item }">
      <template v-if="!item.status">
        <div class="tabe__cell-actions-wrapper">
          <router-link
            class="tabe__btn-action tabe__btn-action--stats"
            :to="isCollegiate ? `/collegiate/match/${item.id}` : `/match/${item.id}`"
            @click.native="trackStatsClick($event, item)"
          >
            <span class="tabe__btn-value">Stats</span>
          </router-link>
          <router-link
            class="tabe__btn-action tabe__btn-action--map"
            :to="getMapToolPath(item)"
            @click.native="trackMapClick($event, item)"
          >
            <span class="tabe__btn-value">2D Map</span>
          </router-link>
          <router-link
            class="tabe__btn-action tabe__btn-action--map"
            :to="getMapToolPath(item, true)"
            @click.native="trackMapClick($event, item)"
          >
            <span class="tabe__btn-value">Presenter</span>
          </router-link>
          <button type="button" class="tabe__btn-action tabe__btn-action--export" @click="exportMatch($event, item)">
            <span class="tabe__btn-value">Export</span>
          </button>
          <router-link class="tabe__btn-action tabe__btn-action--map" :to="getProtoMapToolPath(item)">
            <span class="tabe__btn-value">DEV Map</span>
          </router-link>
        </div>
      </template>
      <template v-else>
        {{ item.status }}
      </template>
    </template>

    <template #cell(quality)="{ item }">
      <CalcMatchQualityCell :item="item" />
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </AppTable>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import EventCell from '@/components/Table/cells/EventCell.vue'
import CalcMatchQualityCell from '@/components/tables/cells/CalcMatchQualityCell.vue'
import mixpanel from '@/mixpanel.js'
import calcBreakdown from '@/utils/calcBreakdown.js'

import isoString from '../../filters/isoString.js'
import localDate from '../../filters/localDate.js'
import localTime from '../../filters/localTime.js'
import BookmarkToggle from '../generic/BookmarkToggle.vue'
import AppTable from '../generic/Table.vue'
import Breakdown from '../match/Breakdown.vue'
import MapCell from '../Table/cells/MapCell.vue'
import TeamCell from '../Table/cells/TeamCell.vue'
import AgentComposition from '../UI/AgentComposition.vue'
import VodStatusIndicator from '../UI/VodStatusIndicator.vue'

export default {
  name: 'MatchesTable',
  components: {
    CalcMatchQualityCell,
    EventCell,
    AgentComposition,
    VodStatusIndicator,
    TeamCell,
    BookmarkToggle,
    MapCell,
    AppTable,
    Breakdown,
  },
  props: {
    bookmarks: px.objectOf(px.bool),
    highlightAgents: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    highlightAgentsOption: px.oneOf(['or', 'and']).def('or'),
    items: px
      .oneOfType([
        px.func,
        px.arrayOf(
          px.shape({
            id: px.string.isRequired,
          }).loose
        ),
      ])
      .isRequired.def([]),
    selected: px.arrayOf(px.shape({ id: px.string.isRequired }).loose),
    trackingContext: px.object,
    bookmarkable: px.bool.def(true),
    isCollegiate: px.bool.def(false),
  },
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),

    computedItems() {
      return this.items.map(item => {
        const teamAAgentIds = item.teams[0].agent_ids
        const teamBAgentIds = item.teams[1].agent_ids
        return {
          ...item,
          teams: item.teams.map(team => {
            return {
              ...team,
              composition: team.agent_ids.map(id => {
                return {
                  id,
                  intersecting: teamAAgentIds.includes(id) && teamBAgentIds.includes(id),
                }
              }),
            }
          }),
        }
      })
    },

    fields() {
      return [
        {
          key: 'interact',
          label: '',
          thClass: 'tabe__cell tabe__cell--interact',
          tdClass: 'tabe__cell tabe__cell--interact',
        },
        {
          key: 'created',
          label: 'Date',
          formatter: localDate,
          sortable: true,
          thClass: 'tabe__cell tabe__cell--date',
          tdClass: 'tabe__cell tabe__cell--date',
        },
        { key: 'map', label: 'Map', thClass: 'tabe__cell tabe__cell--map', tdClass: 'tabe__cell tabe__cell--map' },
        {
          key: 'winnerTeam',
          label: 'Winner Team',
          thClass: 'tabe__cell tabe__cell--winner',
          tdClass: 'tabe__cell tabe__cell--winner',
        },
        { key: 'score', label: 'vs', thClass: 'tabe__cell tabe__cell--score', tdClass: 'tabe__cell tabe__cell--score' },
        {
          key: 'loserTeam',
          label: 'Loser Team',
          thClass: 'tabe__cell tabe__cell--loser',
          tdClass: 'tabe__cell tabe__cell--loser',
        },
        {
          key: 'event',
          label: 'Event',
          thClass: 'tabe__cell tabe__cell--event',
          tdClass: 'tabe__cell tabe__cell--event',
        },
        {
          key: 'rounds_played',
          label: 'Rounds',
          sortable: true,
          thClass: 'tabe__cell tabe__cell--rounds',
          tdClass: 'tabe__cell tabe__cell--rounds',
        },
        {
          key: 'rounds_won_diff',
          label: 'Diff',
          sortable: true,
          thClass: 'tabe__cell tabe__cell--diff',
          tdClass: 'tabe__cell tabe__cell--diff',
        },
        // {
        //   key: 'quality',
        //   label: 'Quality',
        //   thClass: 'tabe__cell tabe__cell--diff',
        //   tdClass: 'tabe__cell tabe__cell--diff',
        // },
        {
          key: 'actions',
          label: '',
          thClass: 'tabe__cell tabe__cell--actions',
          tdClass: 'tabe__cell tabe__cell--actions',
        },
      ]
    },

    selectedIds() {
      return Object.freeze(
        this.selected ? Object.fromEntries(this.selected.map((item, index) => [item.id, index])) : {}
      )
    },
  },
  filters: {
    localDate,
    localTime,
    isoString,
  },
  methods: {
    calcBreakdown,
    exportMatch(event, match) {
      event.stopPropagation()
      this.$emit('export', match)
    },
    getLoserTeam(item) {
      return this.hasWinner(item) ? item.teams.find(team => !team.win) : item.teams[1]
    },
    getMapToolPath(item, presenter) {
      if (!item?.id) return
      const map = this.getMapById(item.map)
      if (!map) return
      const winner = this.getWinnerTeam(item)
      if (!winner) return
      return {
        path: this.isCollegiate ? `/collegiate/map/${map.name}/${winner.id}` : `/map/${map.name}/${winner.id}`,
        query: {
          matches: [item.id],
          presenter: presenter || undefined,
        },
      }
    },
    getProtoMapToolPath(item) {
      if (!item?.id) return
      const map = this.getMapById(item.map)
      if (!map) return
      const winner = this.getWinnerTeam(item)
      if (!winner) return
      return {
        path: `/proto-map/${map.name}/${winner.id}`,
        query: {
          matches: [item.id],
          presenter: undefined,
        },
      }
    },
    getTrackingContext(item) {
      return {
        ...this.trackingContext,
        matchId: item.id,
      }
    },
    getWinnerTeam(item) {
      return this.hasWinner(item) ? item.teams.find(team => team.win) : item.teams[0]
    },
    hasWinner(item) {
      return item.teams.find(team => team.win) != null
    },
    isBookmarked(id) {
      return this.bookmarks?.[id]
    },
    isSelected(item) {
      return item && this.selectedIds[item.id] >= 0
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      return ['tabe__row', { 'tabe__row--selected': this.isSelected(item) }]
    },
    toggleBookmark(item, value) {
      this.$emit('set:bookmark', { item, value })
    },
    toggleSelected(item) {
      if (this.isSelected(item)) {
        const idx = this.selectedIds[item.id]
        const newSelected = [].concat(this.selected.slice(0, idx), this.selected.slice(idx + 1))
        this.$emit('update:selected', newSelected)
        mixpanel.track_deselect({ type: 'match', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      } else {
        const newSelected = [].concat(this.selected, [item])
        this.$emit('update:selected', newSelected)
        mixpanel.track_select({ type: 'match', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      }
    },
    trackStatsClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'match', href: $event.target.href }, ctx)
    },
    trackMapClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, ctx)
    },
    trackVodClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'vod', href: $event.target.href }, ctx)
    },
    calcHighlightAgents(team) {
      if ('and' === this.highlightAgentsOption && Array.isArray(this.highlightAgents)) {
        return this.highlightAgents.every(agentId => team.agent_ids.includes(agentId)) ? this.highlightAgents : {}
      }
      return this.highlightAgents
    },
  },
}
</script>

<style lang="scss">
/*************************************************************************
	Table events
*************************************************************************/
.tabe {
  width: 100%;
  position: relative;

  &__row {
    width: 100%;

    //display: grid;
    //grid-template-columns: 0.5fr 1fr 1fr 2fr 1fr 2fr 0.8fr 0.6fr 1.5fr;

    /* position: relative;
    z-index: 2; */

    &--header {
      background-color: #15171a !important;
      position: sticky;
      top: 0;
      z-index: 4;

      .tabe__cell {
        padding: 12px 0;

        &--interact {
          width: 3rem;
        }

        &--date {
          width: 6rem;
        }

        &--map {
          width: 6rem;
        }

        &--winner {
          min-width: 12rem;
        }

        &--score {
          width: 9em;
        }

        &--loser {
          min-width: 12rem;
        }

        &--event {
          width: 18rem;
        }

        &--rounds {
          width: 6rem;
        }

        &--diff {
          width: 4rem;
        }

        &--actions {
          width: 7rem;
        }
      }
    }

    &:where(:not(.tabe__row--header)) {
      border-top: 1px solid #32383e;
      transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;

      &:nth-child(odd) {
        background-color: #212529 !important;
      }

      &:nth-child(even) {
        background-color: #2c3034 !important;
      }

      &:where(:not(.tabe__row--selected)) {
        &:hover {
          background-color: #323539 !important;
          border-color: #62676c;

          & + .tabe__row:not(.tabe__row--selected) {
            border-color: #62676c;
          }
        }
      }

      .tabe__cell {
        padding: 10px 0;

        // give nested divs a heighted parent to calculate it's % from

        height: 1px;
      }

      .tabe__cell--interact {
        padding: 0;
        border-right: 1px solid #32383e;
      }
    }

    &--selected {
      border-color: #686868;

      &:nth-child(odd),
      &:nth-child(even) {
        // background-color: rgba( $sc-3, 0.2 );
        background-color: $black !important;
      }

      & + .tabe__row {
        border-color: #686868;
      }
    }
  }

  &__cell {
    color: $white;
    text-align: center;

    &--date {
      //display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__cell-interact {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__check {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
  }

  &__check {
    cursor: pointer;

    &.bookmarkable {
      border-bottom: 1px solid #32383e;
    }
  }

  &__vod {
    img,
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__vod {
    margin-bottom: 3%;
  }

  &__btn {
    &:hover {
      .tabe__header-value {
        color: $primary;
      }
    }
  }

  &__header-value {
    font-weight: 700;
    font-size: 14px;
    color: $white;
    line-height: 1;
  }

  &__cell-value {
    font-weight: 400;
    font-size: 12px;
    color: $white;
    line-height: 1.1;
  }

  &__map,
  &__cell-actions-wrapper,
  &__cell--rounds,
  &__cell--diff {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__cell--rounds,
  &__cell--diff {
    display: table-cell;

    .tabe__cell-value {
      font-size: 14px;
    }
  }

  &__datetime {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      line-height: 1.3;
    }
  }

  &__map {
    height: 100%;
  }

  &__cell--winner {
    border-left: 1px solid #32383e;

    .tabe__element-info {
      flex-direction: row-reverse;
    }

    .tabe__element-name {
      text-align: right;
    }

    .tabe__element-wrapper {
      margin-left: 5%;
    }

    .agent-composition {
      justify-content: flex-end;
    }

    .agent-composition-Red {
      background: linear-gradient(to right, adjust-color($atk, $alpha: -1) 0%, $atk 80%, $atk 100%) !important;

      &::after {
        border-color: transparent transparent transparent $atk;
      }
    }

    .agent-composition-Blue {
      background: linear-gradient(to right, adjust-color($def, $alpha: -1) 0%, $def 100%) !important;

      &::after {
        border-color: transparent transparent transparent $def;
      }
    }
  }

  &__cell--loser {
    border-right: 1px solid #32383e;

    .tabe__element-wrapper {
      margin-right: 5%;
    }

    .tabe__element-name {
      text-align: left;
    }

    .agent-composition {
      justify-content: flex-start;
    }

    .agent-composition-Red {
      background: linear-gradient(to right, $atk 0%, adjust-color($atk, $alpha: -1) 100%) !important;

      &::after {
        border-color: transparent $atk transparent transparent;
      }
    }

    .agent-composition-Blue {
      background: linear-gradient(to right, $def 0%, adjust-color($def, $alpha: -1) 100%) !important;

      &::after {
        border-color: transparent $def transparent transparent;
      }
    }
  }

  &__element {
    padding: 0 8%;
  }

  &__element-info {
    margin-bottom: 4%;
    display: flex;
    align-items: center;

    // justify-content: space-between;

    &:hover {
      .tabe__element-name {
        color: $sc-3;
      }
    }
  }

  &__map-wrapper,
  &__element-wrapper {
    width: 46px;
    height: 46px;
    aspect-ratio: 1;
    display: block;
    overflow: hidden;
    border: 1px solid #999;
  }

  &__map-wrapper {
    margin: 0 auto 3%;

    .tabe__element-img {
      width: 98%;
      height: 98%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__element-wrapper {
    padding: 1%;
  }

  &__element-img {
    display: block;
    margin: 0 auto;
  }

  &__element-name {
    max-width: calc(100% - 48px);
    margin-bottom: 4px;
    font-weight: normal;
    font-size: 12px;
  }

  .agent-composition {
    padding: 2px;
    display: flex;
    align-items: center;
    gap: 0 3%;
    position: relative;

    .ic--container {
      width: 25px;
      height: 25px;
      aspect-ratio: 1;
      margin: 0;
      display: inline-block;

      .ic--icon {
        background-color: gray;
        width: 100%;
        height: 100%;
        display: block;

        &:hover {
          background-color: $body-bg;
        }
      }
    }
  }

  &__tooltip-wrapper {
    position: relative;

    &:hover {
      .tabe__tooltip {
        display: block;
        opacity: 1;
      }
    }
  }

  &__tooltip-subwrapper {
    position: relative;
  }

  &__tooltip {
    padding: 5px 4px 6px;
    width: auto;
    display: none;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    border-radius: 4px;
    background-color: $black;
    white-space: nowrap;
    font-weight: 400;
    font-size: 11px;
    color: $white;
    line-height: 1.1;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    z-index: 10;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      transform: translateX(-50%);
      position: absolute;
      left: 50%;
      top: 100%;
      z-index: 2;
    }
  }

  &__cell-score-wrapper {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    text-align: center;
    align-content: center;
  }

  &__cell-breakdown-wrapper {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    text-align: center;
    align-content: center;
  }

  &__cell-score-winner,
  &__cell-score-loser {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
  }

  &__cell-score-winner {
    color: $highlight;
  }

  &__cell-score-separator {
    align-self: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 0.8;
  }

  &__btn-action {
    padding: 0 2%;
    min-width: 100px;
    height: 32px;
    border: 1px solid $sc-3;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    line-height: 1;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    // fix for vertical align of content
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: $sc-3;
      color: color-yiq($sc-3);
    }

    &--vod {
      margin: 0 0.5em;
      min-width: calc(100px - 1em);
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .tabe__bookmark {
    width: 100%;
    flex: 1 0 auto;
    border-bottom: 1px solid #32383e;
  }
}
</style>
