<template>
  <FilterSelection :disabled="disabled" :get-item-string-compare="getAgentNameById" v-model="internalValue">
    <template #selection="{ item }">
      <AgentCell :id="item" variant="row" />
    </template>
    <FormDropdown
      :disabled="disabled"
      text-no-selection="Any agent"
      text-single-selection="1 agent selected"
      :text-multiple-selection="`${internalValue.length} agents selected`"
      v-model="internalValue"
    >
      <DropdownItem
        v-for="agent in agents"
        :key="agent.id"
        :disabled="disabled"
        :selected="selectedById[agent.id]"
        @click.native="toggle(agent.id)"
      >
        <AgentCell :id="agent.id" size="sm" variant="row" />
      </DropdownItem>
    </FormDropdown>
    <FilterOption :value="option" @change="onOptionChange" />
  </FilterSelection>
</template>

<script>
import px from 'vue-types'

import FormDropdown from '@/components/Form/Dropdown.vue'
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'
import AgentCell from '@/components/Table/cells/AgentCell.vue'

import { pxOption } from '../../../types.js'
import DropdownItem from '../../Form/DropdownItem.vue'

import FilterOption from './FilterOption.vue'

export default {
  name: 'AgentFilter',
  components: { FilterOption, DropdownItem, FilterSelection, FormDropdown, AgentCell },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    option: pxOption(),
    value: px.arrayOf(px.string),
  },
  computed: {
    agents() {
      return Object.freeze([...this.$store.getters['static/agents']].sort((a, b) => a.name.localeCompare(b.name)))
    },

    internalValue: {
      get: function () {
        return this.value || []
      },
      set: function (value) {
        return this.$emit('update', this.option === 'and' ? value.slice(0, 5) : value)
      },
    },

    selectedById() {
      return Object.freeze(Object.fromEntries(this.internalValue.map(id => [id, true])))
    },
  },
  methods: {
    getAgentNameById(agentId) {
      return this.$store.getters['static/getAgentById'](agentId).name
    },
    onOptionChange($event) {
      this.$emit('update:option', $event)
      if ($event === 'and') {
        this.internalValue = this.internalValue.slice(0, 5)
      }
    },
    toggle(agentId) {
      if (this.selectedById[agentId]) {
        this.internalValue = this.internalValue.filter(id => id !== agentId)
      } else {
        this.internalValue = [...this.internalValue, agentId]
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
