<template>
  <BFormRadioGroup :options="options" v-model="modelValue" />
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue'
import px from 'vue-types'

import { pxOption } from '../../../types.js'

export default {
  name: 'FilterOption',
  components: {
    BFormRadioGroup,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    andLabel: px.string.def('All (max 5)'),
    orLabel: px.string.def('Any'),
    value: pxOption(),
  },
  computed: {
    andOption() {
      return Object.freeze({ text: this.andLabel, value: 'and' })
    },
    orOption() {
      return Object.freeze({ text: this.orLabel, value: 'or' })
    },
    options() {
      return Object.freeze([this.orOption, this.andOption])
    },
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
}
</script>

<style scoped></style>
