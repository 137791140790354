/**
 * Computes team's breakdown for a match
 */
export default function calcBreakdown(team) {
  return team
    ? {
        ...(team.rounds_first_half_won !== null
          ? {
              first: {
                score: team.rounds_first_half_won,
                role: team.team_side === 'Red' ? 'atk' : 'def',
              },
            }
          : {}),
        ...(team.rounds_second_half_won !== null
          ? {
              second: {
                score: team.rounds_second_half_won,
                role: team.team_side === 'Red' ? 'def' : 'atk',
              },
            }
          : {}),
        ...(team.rounds_overtime_won != null && team.rounds_played > 24
          ? {
              overtime: {
                score: team.rounds_overtime_won,
              },
            }
          : {}),
      }
    : null
}
