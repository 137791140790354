<template v-if="haveScore">
  <div v-if="breakdown" class="score">
    <span
      :class="'score--num score--num__' + (breakdown.first ? breakdown.first.role : '')"
      v-b-tooltip.hover.noninteractive
      :title="breakdown.first != null ? 'First half' : 'Missing information'"
    >
      {{ breakdown.first != null ? breakdown.first.score : 'NA' }}</span
    >
    /
    <span
      :class="'score--num score--num__' + (breakdown.second ? breakdown.second.role : '')"
      v-b-tooltip.hover.noninteractive
      :title="breakdown.second != null ? 'Second half' : 'Missing information'"
    >
      {{ breakdown.second != null ? breakdown.second.score : 'NA' }}
    </span>
    <template v-if="breakdown.overtime != null">
      /
      <span
        :class="`score--num score--num__overtime`"
        v-b-tooltip.hover.noninteractive
        :title="breakdown.overtime != null ? 'Overtime' : 'Missing information'"
      >
        {{ breakdown.overtime.score }}
      </span>
    </template>
  </div>
</template>

<script>
import px from 'vue-types'

import { pxNullable, pxRoleLowercase } from '../Map/types'

export default {
  name: 'Breakdown',
  props: {
    breakdown: pxNullable(
      px.shape({
        first: pxNullable(
          px.shape({
            score: px.integer.def(0),
            role: pxRoleLowercase(),
          })
        ),
        second: pxNullable(
          px.shape({
            score: px.integer.def(0),
            role: pxRoleLowercase(),
          })
        ),
        overtime: pxNullable(
          px.shape({
            score: px.integer.def(0),
          })
        ),
      })
    ),
  },
  computed: {
    haveScore() {
      return !!(this.breakdown && (this.breakdown.first || this.breakdown.second || this.breakdown.overtime))
    },
  },
}
</script>

<style lang="scss" scoped>
.score {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  &--num {
    &__atk {
      color: $atk;
    }

    &__def {
      color: $def;
    }
  }

  span {
    padding-right: 3px;
    padding-left: 3px;
  }
}
</style>
