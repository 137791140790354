<template>
  <div class="home matches-page">
    <PageTitle>Collegiate Matches</PageTitle>

    <AppTabs lazy>
      <AppTab title="Search">
        <SearchStateController ref="searchCtrl" :default="defaultFilters" :s="s" @change="onSearchChange">
          <template #default="{ state }">
            <CollegiateSearchFilters :disabled="!!loading" :value="state" @update="onSearch" />

            <hr />

            <AppTabs>
              <AppTab title="Found matches">
                <CollegiateMatchSearchController
                  :filter="state"
                  :per-page="perPage"
                  @update:per-page="updateItemsPerPage"
                  :selected.sync="selectedMatches"
                  :table="$options.components.MatchBookmarkController"
                  :tracking-context="{ page: 'matches', section: 'results' }"
                />
              </AppTab>

              <AppTab :title="`Selected matches (${selectedMatchesCount})`">
                <MatchesTable
                  :items="selectedMatches"
                  :selected.sync="selectedMatches"
                  :tracking-context="{ page: 'matches', section: 'selected' }"
                  v-bind="$attrs"
                  v-on="$listeners"
                  :bookmarkable="false"
                  :is-collegiate="true"
                />
              </AppTab>

              <!-- <MapTab
                :active.sync="mapTool.active"
                :matches="selectedMatches"
                :map.sync="mapTool.map"
                :team.sync="mapTool.team"
                :tracking-context="{ page: 'matches', section: 'map' }"
              /> -->
            </AppTabs>
          </template>
        </SearchStateController>
      </AppTab>
    </AppTabs>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters, mapMutations } from 'vuex'

import CollegiateMatchSearchController from '@/components/controllers/search/CollegiateMatch.vue'
import SearchStateController from '@/components/controllers/SearchStateController.vue'
import PageTitle from '@/components/generic/PageTitle.vue'
import AppTab from '@/components/generic/Tab.vue'
import AppTabs from '@/components/generic/Tabs.vue'
import { pxNullable } from '@/components/Map/types.js'
import CollegiateSearchFilters from '@/components/Search/CollegiateFilters.vue'
import MatchesTable from '@/components/tables/MatchesTable.vue'
import mixpanel from '@/mixpanel.js'
import { DEFAULT_ITEMS_PER_PAGE } from '@/store/modules/search'
import { serializeQuery } from '@/utils/searchQuery.js'

export default {
  name: 'CollegiateMatches',
  components: {
    SearchStateController,
    CollegiateMatchSearchController,
    AppTab,
    AppTabs,
    CollegiateSearchFilters,
    PageTitle,
    MatchesTable,
  },
  props: {
    s: pxNullable(px.string).def(null),
  },
  data() {
    return {
      error: null,
      loading: 0,
      perPage: DEFAULT_ITEMS_PER_PAGE,
      mapTool: {
        active: false,
        map: null,
        team: null,
      },
      selectedMatches: [],
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'collegiateSearch/matchSearchFilters',
      defaultItemsPerPage: 'collegiateSearchitemsPerPage',
    }),
    selectedMatchesCount() {
      return this.selectedMatches?.length
    },
  },
  mounted() {
    this.perPage = this.defaultItemsPerPage
  },
  methods: {
    ...mapMutations({
      resetFilters: 'collegiateSearch/RESET_FILTERS',
      saveFilters: 'collegiateSearch/SET_FILTERS',
      setItemsPerPage: 'collegiateSearch/SET_ITEMS_PER_PAGE',
    }),

    async onSearch(query) {
      this.loading++
      try {
        this.selectedMatches = []
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        } else {
          await this.$refs.searchCtrl.retry()
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },

    onSearchChange() {
      this.selectedMatches = []
    },

    updateItemsPerPage(count) {
      this.perPage = count
      this.setItemsPerPage(count)
    },
  },
}
</script>

<style lang="scss" scoped></style>
