<template>
  <div class="vod-status-indicator" :class="variant">
    <div :class="_status" v-b-tooltip.hover.noninteractive :title="variant === 'short' ? title : undefined">
      <component :is="icon" />
      <span v-if="variant === 'long'">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import vodStatus, { NO_VIDEO, UNKNOWN_VOD_STATUS } from '../../utils/vodStatus.js'

export default {
  props: {
    status: String,
    variant: px.oneOf(['short', 'long']).def('short'),
  },
  computed: {
    _status() {
      return this.status || 'none'
    },
    statusObj() {
      return this.status ? vodStatus[this.status] || UNKNOWN_VOD_STATUS : NO_VIDEO
    },
    icon() {
      return this.statusObj.icon
    },
    title() {
      return this.statusObj.label
    },
  },
}
</script>

<style lang="scss" scoped>
.vod-status-indicator {
  .provisioning svg {
    fill: $info;
  }

  .executing svg {
    fill: $info;
  }

  .partial svg {
    fill: $info;
  }

  .succeeded svg {
    fill: $success;
  }

  .none svg {
    fill: $danger;
  }

  .failed svg {
    fill: $danger;
  }

  &.long {
    svg {
      margin-right: 0.5em;
    }
  }
}
</style>
