<template>
  <div class="filter-period" :class="`filter-period--${internalOption}`">
    <FormDropdown ref="dropdown" :disabled="disabled">
      <template #button-content> {{ optionsLabels[internalOption] }}</template>

      <template #default>
        <DropdownItem
          v-for="option in options"
          :key="option.value"
          :selected="internalOption === option.value"
          @click.native="onOptionClick(option)"
        >
          {{ option.text }}
        </DropdownItem>
      </template>
    </FormDropdown>

    <div v-if="isCustomOption" class="d-flex flex-row flex-nowrap justify-content-around">
      <ADatepicker
        label="From date"
        :disabled="disabled"
        :date-format-options="dateFormatOptions"
        :max="internalEndTime"
        v-model="internalBeginTime"
      />
      <ADatepicker
        label="To date"
        :disabled="disabled"
        :date-format-options="dateFormatOptions"
        :min="internalBeginTime"
        v-model="internalEndTime"
      />
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import FormDropdown from '@/components/Form/Dropdown.vue'
import DropdownItem from '@/components/Form/DropdownItem.vue'

import { CUSTOM_OPTION, ALL_TIME_OPTION, packPeriod, unpackPeriod } from '../../../utils/period.js'
import ADatepicker from '../../Form/Datepicker.vue'
import { pxNullable } from '../../Map/types.js'

const addDays = (date, days) => {
  if (!date) return date
  return new Date(new Date(date).getTime() + days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
}

export default {
  name: 'PeriodFilter',
  components: {
    DropdownItem,
    FormDropdown,
    ADatepicker,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    value: px.shape({
      begin_time: pxNullable(px.string),
      end_time: px.string,
    }),
  },
  computed: {
    dateFormatOptions() {
      return Object.freeze({ year: 'numeric', month: 'short', day: '2-digit' })
    },

    internalBeginTime: {
      get() {
        return this.internalValue.begin_time
      },
      set(begin_time) {
        this.internalValue = {
          ...this.internalValue,
          begin_time,
        }
      },
    },

    internalEndTime: {
      get() {
        return addDays(this.internalValue.end_time, -1)
      },
      set(end_time) {
        this.internalValue = {
          ...this.internalValue,
          end_time: addDays(end_time, 1),
        }
      },
    },

    internalOption: {
      get() {
        return this.internalValue.option
      },
      set(option) {
        this.internalValue = {
          ...this.internalValue,
          option,
        }
      },
    },

    internalValue: {
      get() {
        return Object.freeze(unpackPeriod(this.value))
      },
      set(value) {
        this.$emit('update', Object.freeze(packPeriod(value)))
      },
    },

    isCustomOption() {
      return this.internalOption === CUSTOM_OPTION
    },

    options() {
      return [
        { text: 'Last 1 week', value: '1 week' },
        { text: 'Last 1 month', value: '1 month', default: true },
        { text: 'Last 3 months', value: '3 months' },
        { text: 'Last 6 months', value: '6 months' },
        { text: 'Last year', value: '1 year' },
        { text: 'All time', value: ALL_TIME_OPTION },
        { text: 'Custom period', value: CUSTOM_OPTION },
      ]
    },

    optionsLabels() {
      return Object.freeze(Object.fromEntries(this.options.map(({ text, value }) => [value, text])))
    },
  },
  methods: {
    onOptionClick(option) {
      this.internalOption = option.value
      this.$refs.dropdown.hide()
    },
  },
}
</script>

<style lang="scss">
.filter-period {
  &:not(.filter-period--custom) {
    padding-bottom: 30px;
  }
}
</style>
