<template>
  <div class="container-fluid" data-testid="search-filters">
    <b-form :disabled="disabled" @submit.prevent="onSubmit" @reset="onReset">
      <div class="filters">
        <div class="filters--item filters--item__teams" data-testid="filter-teams">
          <div class="filters--item--title">Teams:</div>
          <div>
            <CollegiateTeamFilter :disabled="disabled" v-model="currentFilters.teams" />
            <FilterOption and-label="Both(2)" v-model="currentFilters.teams_option" />
          </div>
        </div>

        <div class="filters--item filters--item__agents">
          <div class="filters--item--title">Agents:</div>
          <AgentFilter
            :disabled="disabled"
            :option.sync="currentFilters.agent_option"
            v-model="currentFilters.agent_ids"
          />
        </div>
        <div class="filters--item filters--item__maps">
          <div class="filters--item--title">Maps:</div>
          <MapFilter :disabled="disabled" v-model="currentFilters.map_ids" />
        </div>
        <div v-if="!hideBrokenStuff" class="filters--item filters--item__players">
          <div class="filters--item--title">Players:</div>
          <CollegiatePlayerFilter
            :disabled="disabled"
            :option.sync="currentFilters.players_option"
            v-model="currentFilters.players"
          />
        </div>
        <div class="filters--item filters--item__regions">
          <div class="filters--item--title">Regions:</div>
          <RegionFilter :disabled="disabled" v-model="currentFilters.platforms" />
        </div>
        <div class="filters--item filters--item__events">
          <div class="filters--item--title">Events:</div>
          <EventFilter :disabled="disabled" v-model="currentFilters.events" />
        </div>
        <div class="filters--item filters--item__period">
          <div class="filters--item--title">Period:</div>
          <PeriodFilter :disabled="disabled" v-model="filter_period" />
        </div>
        <div class="filters--item filters--item__vod">
          <div class="filters--item--title">Video:</div>
          <b-form-group label-for="filter-has-vod">
            <b-form-checkbox class="mb-1" id="filter-has-vod" v-model="currentFilters.has_vod">
              Only matches with VOD
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
      <b-row>
        <!-- Search Button -->
        <b-col sm="12">
          <div class="btn-actions">
            <b-form-group>
              <button
                ref="searchBtn"
                type="submit"
                class="btn btn-primary"
                :class="{ highlight: changed }"
                :disabled="disabled"
              >
                <BIconSearch />
                Search
              </button>

              <BTooltip v-if="$refs.searchBtn" :show="changed" :target="$refs.searchBtn" triggers="manual">
                Apply new filters
              </BTooltip>

              <b-button type="reset" variant="outline-warning" :disabled="disabled">
                <BIconArrowClockwise />
                Reset
              </b-button>
            </b-form-group>

            <!-- <b-form-group>
              <b-button variant="outline-warning" @click="savedSearch = !savedSearch">
                <span v-if="savedSearch">
                  <BIconStarFill />
                  Saved search
                </span>
                <span v-else>
                  <BIconStar />
                  Save search
                </span>
              </b-button>
            </b-form-group> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BIconArrowClockwise,
  BIconSearch,
  BRow,
  BTooltip,
} from 'bootstrap-vue'
import px from 'vue-types'

import CollegiatePlayerFilter from '@/components/Search/collegiateFilters/PlayerFilter.vue'
import CollegiateTeamFilter from '@/components/Search/collegiateFilters/TeamFilter.vue'
import AgentFilter from '@/components/Search/filters/AgentFilter.vue'
import EventFilter from '@/components/Search/filters/EventFilter.vue'
import MapFilter from '@/components/Search/filters/MapFilter.vue'
import RegionFilter from '@/components/Search/filters/RegionFilter.vue'

import { pxOption } from '../../types.js'
import deepEqual from '../../utils/deepEqual.js'
import { pxNullable } from '../Map/types.js'

import FilterOption from './filters/FilterOption.vue'
import PeriodFilter from './filters/PeriodFilter.vue'

export default {
  name: 'CollegiateSearchFilters',

  model: {
    prop: 'value',
    event: 'update',
  },

  components: {
    CollegiateTeamFilter,
    AgentFilter,
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BIconArrowClockwise,
    BIconSearch,
    BRow,
    BTooltip,
    FilterOption,
    MapFilter,
    PeriodFilter,
    CollegiatePlayerFilter,
    RegionFilter,
    EventFilter,
  },

  props: {
    disabled: px.bool.def(false),
    value: px.shape({
      teams: px.arrayOf(px.object),
      teams_option: pxOption(),
      agent_ids: px.arrayOf(px.string).def([]),
      agent_option: pxOption(),
      map_ids: px.arrayOf(px.string),
      players: px.arrayOf(px.object),
      players_option: pxOption(),
      platforms: px.array.def([]),
      begin_time: pxNullable(px.string),
      end_time: pxNullable(px.string),
      has_vod: px.bool,
    }),
  },

  data() {
    return {
      currentFilters: {
        ...this.value,
      },

      savedSearch: false,
    }
  },

  computed: {
    changed() {
      return !deepEqual(this.currentFilters, this.value)
    },

    filter_period: {
      get() {
        return {
          begin_time: this.currentFilters.begin_time,
          end_time: this.currentFilters.end_time,
        }
      },
      set({ begin_time, end_time }) {
        this.$set(this.currentFilters, 'begin_time', begin_time)
        this.$set(this.currentFilters, 'end_time', end_time)
      },
    },
  },

  watch: {
    'currentFilters.teams'() {
      this.trimExtraTeams()
    },
    'currentFilters.teams_option'() {
      this.trimExtraTeams()
    },
    'value': {
      immediate: true,
      handler(val) {
        this.currentFilters = {
          ...val,
        }
      },
    },
  },

  methods: {
    onSubmit() {
      this.$emit('update', this.currentFilters)
    },

    onReset() {
      this.$emit('update', null)
    },

    trimExtraTeams() {
      if (this.currentFilters.teams_option === 'and') {
        if (this.currentFilters.teams.length > 2) {
          this.currentFilters.teams = this.currentFilters.teams.slice(0, 2)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
  margin-bottom: 3rem;
}

.btn-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 1em 0 0;

  .form-group {
    margin-bottom: 0;
  }
}

/* .filters {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(14em, 1fr) );
    grid-gap: 0;

    &--item {
      padding: 0 1em 2rem;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      // border: 1px solid #ccc;

      &__period {
        padding-bottom: calc(1.5rem + 0.5em - 1rem);
      }

      &__maps, &__regions {
        padding-bottom: calc(1.5rem + 0.5em);
      }

      &__period {
        grid-column-end: span 2;
      }

      &--title {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $gray-2;
        font-size: 1.2em;
        line-height: 1.1;
      }
    }
  } */
</style>
