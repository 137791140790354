import {
  BIconCameraVideoFill,
  BIconCameraVideoOffFill,
  BIconCpuFill,
  BIconLayersFill,
  BIconQuestion,
  BIconX,
  BIconCameraVideo,
} from 'bootstrap-vue'

const STATUSES = {
  provisioning: {
    icon: BIconLayersFill,
    label: 'Match video is being provisioned',
  },
  executing: {
    icon: BIconCpuFill,
    label: 'Match is being processed',
  },
  succeeded: {
    icon: BIconCameraVideoFill,
    label: 'Match videos are available',
  },
  partial: {
    icon: BIconCameraVideo,
    label: 'Match videos are partially available',
  },
  failed: {
    icon: BIconX,
    label: 'Match video failed',
  },
}

export const STATUS_KEYS = Object.keys(STATUSES)

export const NO_VIDEO = {
  icon: BIconCameraVideoOffFill,
  label: 'No videos for this match',
}

export const UNKNOWN_VOD_STATUS = {
  icon: BIconQuestion,
  label: 'Unknown match video status',
}

export default STATUSES
