<template>
  <div>
    <ul class="list-selected">
      <li class="list-selected-item" v-for="displayValue in displayValues" :key="displayValue.id">
        <div name="selection" class="list-selected-item__value">
          <slot name="selection" v-bind="displayValue" />
        </div>

        <div v-if="!disabled" class="list-selected-item__actions">
          <BButtonClose text-variant="danger" @click="remove(displayValue)" />
        </div>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { BButtonClose } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  name: 'FilterSelection',
  components: {
    BButtonClose,
  },
  model: {
    prop: 'items',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    items: px.array.isRequired,
    // px.func does not support def(null)
    getItemId: Function,
    getItemStringCompare: Function,
  },
  computed: {
    // bootstrap model requires a prop
    displayValues() {
      const displayValues = this.items.map((item, index) => ({
        item,
        index,
        id: this.getItemId ? this.getItemId(item) : ['string', 'number'].includes(typeof item) ? item : index,
      }))
      if (this.getItemStringCompare) {
        displayValues.sort((a, b) => this.getItemStringCompare(a.item).localeCompare(this.getItemStringCompare(b.item)))
      }
      return displayValues
    },
  },
  methods: {
    remove(displayValue) {
      this.$emit('remove', displayValue.item, displayValue.index, this.items)
      this.$emit(
        'update',
        this.items.filter(item => item !== displayValue.item)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.list-selected {
  list-style: none;
  padding: 0;

  &-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &-actions {
    float: right;

    @media (hover: hover) {
      .list-selected-item:not(:hover) & {
        display: none;
      }
    }
  }
}
</style>
