<template>
  <b-dropdown
    ref="dropdown"
    :class="{ 'disabled-input-styles': disabled }"
    :disabled="disabled"
    :style="{ transition: 'opacity 500ms ease' }"
  >
    <template #button-content>
      <slot name="button-content">
        <span v-if="!value.length">
          {{ textNoSelection }}
        </span>
        <span v-if="value.length === 1">
          {{ textSingleSelection }}
        </span>
        <span v-if="value.length > 1">
          {{ textMultipleSelection }}
        </span>
      </slot>
    </template>

    <slot>
      <b-form-checkbox-group :disabled="disabled" :options="options" :checked="value" @input="$emit('input', $event)" />
    </slot>
  </b-dropdown>
</template>

<script>
import { BDropdown, BFormCheckboxGroup } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  name: 'FormDropdown',
  components: {
    BDropdown,
    BFormCheckboxGroup,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: px.array,
    options: px.array,
    textNoSelection: px.string.def('Please select'),
    textSingleSelection: px.string.def('1 selected'),
    textMultipleSelection: px.string.def('1 selected'),
    label: px.stirng,
    disabled: px.bool,
  },
  methods: {
    hide(refocus) {
      return this.$refs.dropdown.hide(refocus)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .dropdown-toggle {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
  }
}
</style>
