import * as Sentry from '@sentry/vue'

import { createShortcut, getShortcut } from '../api/shortcuts.js'
import axios from '../axios.js'

const CACHE = {}

export async function serializeQuery(query) {
  if (!query) {
    return null
  }
  try {
    const id = await createShortcut(query)
    CACHE[id] = JSON.stringify(query)
    return id
  } catch (e) {
    if (axios.isCancel(e)) {
      return
    }
    console.error('Failed to create shortcut', axios.extractErrorMessage(e), e)
    Sentry.captureException(e)
    throw e
  }
}

export async function deserializeQuery(string, config) {
  if (!string) {
    return null
  }
  if (string in CACHE) {
    return JSON.parse(CACHE[string])
  }
  try {
    const state = await getShortcut(string, config)
    CACHE[string] = JSON.stringify(state)
    return state
  } catch (e) {
    if (axios.isCancel(e)) {
      return
    }
    console.error('Failed to get shortcut', axios.extractErrorMessage(e), e)
    Sentry.captureException(e)
    throw e
  }
}
