<template>
  <div>
    <LoadingCell v-if="loading" />
    <BIconExclamationCircle v-else-if="error" class="text-danger" :title="error" />
    <template v-else-if="overview">
      <div>Match: {{ percentage(overview.matchPositionsPercentage) }}</div>
      <div>Best #{{ overview.bestRound.round_num + 1 }}: {{ percentage(overview.bestRound.perc) }}</div>
    </template>
    <template v-else>-</template>
  </div>
</template>

<script>
import { BIconExclamationCircle } from 'bootstrap-vue'

import { getMatchRiotData } from '@/api/matches'
import { getMatchRoundAdvancedData } from '@/api/matches/analytics'
import axios from '@/axios'
import LoadingCell from '@/components/Table/cells/LoadingCell.vue'
import { calcAvailablePositions } from '@/utils/processMapToolData'

export default {
  name: 'CalcMatchQualityCell',
  components: { BIconExclamationCircle, LoadingCell },
  props: {
    item: Object,
  },
  data: () => ({
    error: null,
    loading: true,
    overview: null,
  }),
  watch: {
    item: {
      immediate: true,
      handler(item) {
        this.load(item)
      },
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async load(matchSearchResult) {
      this.error = null
      this.overview = null
      this.loading = true
      try {
        this.cancelRequest()
        if (matchSearchResult.vod_status !== 'succeeded') {
          return
        }
        this.cancelTokenSource = axios.CancelToken.source()
        const cancelToken = this.cancelTokenSource.token

        const [riotData, ...detectedData] = await Promise.all([
          getMatchRiotData({ matchId: matchSearchResult.id, isCollegiate: false }, { cancelToken }),
          ...Array.from({ length: Math.min(100, matchSearchResult.rounds_played) }, async (_, i) =>
            getMatchRoundAdvancedData({ matchId: matchSearchResult.id, roundNum: i }, { cancelToken })
          ),
        ])

        const roundsData = Object.fromEntries(
          riotData.rounds.map(round => [
            round.round_num,
            {
              kills: round.kills.sort((a, b) => a.round_time_millis - b.round_time_millis),
              positions: round.player_locations,
              round_length_millis: round.round_length_millis,
            },
          ])
        )
        let detected = 0
        let total = 0
        const roundsPositionsStats = await Promise.all(
          detectedData.map(async (detectedRound, round_num) => {
            const { availablePositions, totalPositions } = calcAvailablePositions(
              roundsData[round_num].positions,
              detectedRound.minimap_detections_expand?.player_locations,
              roundsData[round_num].round_length_millis,
              roundsData[round_num].kills,
              0,
              matchSearchResult.id + '#' + round_num
            )
            detected += availablePositions
            total += totalPositions
            return { perc: availablePositions / totalPositions, available: availablePositions, total: totalPositions }
          })
        )

        this.overview = {
          matchPositionsPercentage: detected / total,
          detected,
          total,
          roundsPositionsStats,
          bestRound: roundsPositionsStats.reduce(
            (best, round, i) => (round.perc > best.perc ? { ...round, round_num: i } : best),
            { perc: 0 }
          ),
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = e
      } finally {
        this.loading = false
      }
    },
    percentage(value) {
      return `${(value * 100).toFixed(2)}%`
    },
  },
}
</script>

<style scoped></style>
