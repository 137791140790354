<template>
  <div class="agent-composition">
    <AgentCell
      v-for="(agent, idx) in computedAgents"
      :key="agent.id + idx"
      v-bind="agent"
      :highlight="isAgentHighlighted(agent)"
      :size="size"
      variant="icon"
    />
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import AgentCell from '../Table/cells/AgentCell.vue'

export default {
  name: 'AgentComposition',
  components: { AgentCell },
  props: {
    agent_ids: px.arrayOf(px.string).def(null),
    agents: px
      .arrayOf(
        px.shape({
          id: px.string,
          intersecting: px.bool.def(false),
        })
      )
      .def(null),
    highlight: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    size: px.oneOf(['xs', 'sm', 'md', 'lg']).def('xs'),
  },

  computed: {
    ...mapGetters({
      getAgentById: 'static/getAgentById',
    }),
    computedAgents() {
      return Object.freeze(
        this.agents
          ? this.agents
              ?.filter(({ id }) => id)
              .map(({ id, intersecting }) => {
                const agent = this.getAgentById(id)
                return {
                  ...agent,
                  intersecting,
                }
              })
              .sort((a, b) => {
                if (a.intersecting) {
                  if (b.intersecting) {
                    return a.name < b.name ? -1 : 1
                  }
                  return -1
                } else {
                  if (b.intersecting) {
                    return 1
                  }
                  return a.name < b.name ? -1 : 1
                }
              })
          : this.agent_ids
              ?.filter(id => id)
              .map(this.getAgentById)
              .sort((a, b) => (a.name < b.name ? -1 : 1))
      )
    },

    highlightedByIds() {
      if (Array.isArray(this.highlight)) {
        return Object.fromEntries(this.highlight.map(id => [id, true]))
      }
      return this.highlight ?? {}
    },
  },
  methods: {
    isAgentHighlighted(agent) {
      return this.highlightedByIds[agent.id]
    },
  },
}
</script>

<style lang="scss" scoped>
.agent-composition {
  display: flex;
}
</style>
